import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import Lottie from "react-lottie";

import * as menuAnimation from "../../lottie/menu.json";

import FB from "../../images/common/fb.svg";
import In from "../../images/common/linkedin.svg";
import Dark from "../../images/common/dark-theme.svg";
import Light from "../../images/common/light-theme.svg";

import useImage from "../../hooks/use-image";
import { ThemeContext } from "../../../provider";
import SideNav from "./sidenav";
const menuOptions = {
  autoplay: false,
  loop: false,
  animationData: menuAnimation.default,
};

export default function Header() {
  const [isNavExpanded, toggleExpansion] = useState(false);
  const { logoDark, logoLight } = useImage();
  const closeMenuRef = useRef();

  const handleFocusOnClose = () => {
    toggleExpansion(!isNavExpanded);
  };

  return (
    <ThemeContext.Consumer>
      {(context) => (
        <header
          className={`bg-ci-blue-700 fixed flex h-full justify-between items-center p-4 w-full top-0 left-0 z-20 ${
            context.isDark ? `` : `shadow-lg`
          }`}
          style={{ maxHeight: "96px" }}
          id="top"
        >
          <button
            onClick={() => toggleExpansion(!isNavExpanded)}
            ref={closeMenuRef}
            className="cursor-pointer menu-icon"
          >
            <span className="sr-only">Menu</span>
            <Lottie
              height={56}
              width={56}
              options={menuOptions}
              speed={isNavExpanded ? 1 : 2.5}
              direction={isNavExpanded ? 1 : -1}
            />
          </button>
          <Link to={`/`}>
            <Image
              fixed={
                context.isDark ? logoLight.sharp.fixed : logoDark.sharp.fixed
              }
              className="logo"
            />
            <span className="sr-only">Clarity Innovations</span>
          </Link>
          <SideNav open={isNavExpanded} onClick={handleFocusOnClose} />
          <ul className="flex flex-row">
            <li className="p-2 pr-3 md:pr-6">
              <button onClick={() => context.changeTheme()} className="w-8 h-8">
                <span className="sr-only">Switch theme modes</span>
                {context.isDark ? <Dark /> : <Light />}
              </button>
            </li>
            <li className="hidden sm:p-2 sm:block">
              <a
                href="https://facebook.com/clarityinnovations"
                rel="noopenner noreferrer"
                target="_blank"
              >
                <FB className="w-6 h-6" />
                <span className="sr-only">Clarity Innovations Facebook</span>
              </a>
            </li>
            <li className="hidden sm:p-2 sm:block">
              <a
                href="https://linkedin.com/company/clarityinnovations"
                rel="noopenner noreferrer"
                target="_blank"
              >
                <In className="w-6 h-6" />
                <span className="sr-only">Clarity Innovations LinkedIn</span>
              </a>
            </li>
          </ul>
           <img
            src="https://segment.prod.bidr.io/associate-segment?buzz_key=carbonreach&segment_key=carbonreach-922&value="
            className="sr-only"
            role="presentation"
          />
        </header>
      )}
    </ThemeContext.Consumer>
  );
}
